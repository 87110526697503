(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/template-literals/active-pause.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/template-literals/active-pause.js');
'use strict';

svs.accountservices.player_exclusions.template_literals.active_pause = data => {
  let title = data.noTitle ? '' : "<p class=\"f-bold\">Spelpaus aktiverad!</p>";
  !data.noTitle && data.isExpired && (title = "<p class=\"f-bold\">Spelpaus fortfarande aktiverad!</p>");
  const content = data.isExpired ? "\n    <p>\n      Din spelpaus har upph\xF6rt men du beh\xF6ver kontakta v\xE5r kundservice f\xF6r att l\xE5sa upp ditt spelkonto. Ring <a\n      href=\"tel:0770-11-11-11\"><span class=\"alert-text-link\">0770 11 11 11</span></a>\n    </p>\n    " : "\n    <p>\n      Du kan st\xE4nga av dig f\xF6r spel hos samtliga licensierade spelbolag i Sverige hos Spelinspektionen. Se <a\n      href=\"https://www.spelpaus.se\"><span class=\"alert-text-link\">www.spelpaus.se</span></a>.\n    </p>\n    ";
  return "".concat(title, "\n    <p>\n      Du \xE4r pausad fr\xE5n:<br>\n      <span class=\"f-capitalize\">").concat(data.vertical, "</span> (").concat(data.isExpired ? 'upphörde' : 'till och med', " ").concat(data.expiry, ").\n    </p>\n    ").concat(content, "\n  ");
};

 })(window);