(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/data/definitions.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/data/definitions.js');

'use strict';

const subSystems = ['alla', 'bingo', 'casino', 'poker', 'sport', 'tipsspel', 'oddset'];
const pauseTypes = {
  GamingPause24: 1,
  PauseSixMonths: 2,
  PauseThirtySixMonths: 3,
  PauseThreeMonths: 4,
  PauseTwelveMonths: 5,
  PauseTwelveMonthsGcs: 6,
  PauseTwentyFourHours: 7,
  PauseTwentyFourHoursGcs: 8
};
const verticals = {
  ALLA: 'Alla spel',
  BINGO: 'Bingo',
  CASINO: 'Casino',
  POKER: 'Poker',
  SPORT: 'Sportspel',
  ODDSET: 'Oddset',
  TIPSSPEL: 'Tipsspel'
};
const gcsProducts = {
  alla: 'All',
  bingo: 'Bingo',
  casino: 'NetCasino',
  poker: 'Poker',
  sport: 'SportsBetting'
};
const gcsLevel = {
  STEP2: 'LevelTwo',
  STEP4: 'LevelFour'
};
const restrictionReasons = {
  TT: 'TT_limitation',
  GCS: 'GCS_limitation',
  DLR: 'Customer_without_deposit_limits',
  AMD: 'Awaiting_max_deposits',
  TMDTL: 'Too_many_deposits_today_limitation'
};

const gcsLimitations = {
  gcsCutoffAge: 25,
  gcsYoungAmmount: 5000,
  gcsAmmount: 10000,
  gcsStatus: {
    oneHourPauseIds: [1, 2, 3],
    twelveHourPauseIds: [5, 6, 7] 
  }
};
const definitions = {
  gcsLevel,
  restrictionReasons,
  gcsLimitations,
  gcsProducts,
  pauseTypes,
  subSystems,
  verticals
};
if (typeof exports === 'object') {
  module.exports = {
    definitions
  };
} else {
  svs.accountservices.player_exclusions.definitions = definitions;
}

 })(window);