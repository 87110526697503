(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/data/exclusion.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/data/exclusion.js');

'use strict';

function getDependencies() {
  if (typeof exports === 'object') {
    return require('./definitions.es6');
  }
  return svs.accountservices.player_exclusions.definitions;
}
const {
  verticals,
  pauseTypes
} = getDependencies();
class Exclusion {
  constructor() {
    let {
      needSelfTest = false,
      selfTestExpiry,
      expiry,
      isExpired = false,
      type,
      vertical
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.expiry = expiry;
    this.isExpired = isExpired;
    this.needSelfTest = needSelfTest;
    this.selfTestExpiry = selfTestExpiry;
    this.type = type;
    this.vertical = vertical;
  }
  get is24HourExclusion() {
    return this.type === pauseTypes.GamingPause24 || this.type === pauseTypes.PauseTwentyFourHours || this.type === pauseTypes.PauseTwentyFourHoursGcs;
  }
  get gcsVertical() {
    let gcsVertical;
    switch (this.vertical) {
      case verticals.ALLA:
        gcsVertical = 'All';
        break;
      case verticals.BINGO:
        gcsVertical = 'Bingo';
        break;
      case verticals.CASINO:
        gcsVertical = 'NetCasino';
        break;
      case verticals.POKER:
        gcsVertical = 'Poker';
        break;
      case verticals.SPORT:
        gcsVertical = 'SportsBetting';
        break;
      default:
    }
    return gcsVertical;
  }
  toString() {
    return this.vertical;
  }
}
if (typeof exports === 'object') {
  module.exports = {
    Exclusion
  };
} else {
  svs.accountservices.player_exclusions.Exclusion = Exclusion;
}

 })(window);