(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/data/compressor.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/data/compressor.js');

'use strict';

const length = 65535;
function toCustomBase(num) {
  let result = '';
  while (num > 0) {
    let pos = 1;
    while (num > length ** pos) {
      pos++;
    }
    let cVal;
    let deduct;
    if (pos > 1) {
      cVal = length ** Math.max(pos - 1, 1);
      deduct = Math.floor(num / cVal);
    } else {
      cVal = 1;
      deduct = num;
    }
    num -= deduct * cVal;
    result += String.fromCharCode(deduct);
  }
  return result;
}
function fromCustomBase(str) {
  const strLen = str.length - 1;
  let result = 0;
  str.split('').forEach((c, index) => {
    let cVal = 1;
    const rev = strLen - index;
    if (rev > 0) {
      cVal = length ** rev;
    }
    result += str.charCodeAt(index) * cVal;
  });
  return result;
}
function getDefinitions() {
  if (typeof exports === 'object') {
    return require('./definitions.es6').definitions;
  }
  return svs.accountservices.player_exclusions.definitions;
}
function getExclusion() {
  if (typeof exports === 'object') {
    return require('./exclusion.es6');
  }
  return svs.accountservices.player_exclusions;
}
const {
  verticals
} = getDefinitions();
const {
  Exclusion
} = getExclusion();
const pauseLength = 8;
function padFront(num) {
  let length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  let result = String(num);
  while (result.length < length) {
    result = "0".concat(result);
  }
  return result;
}
function dateToString(expiry) {
  if (expiry) {
    return toCustomBase(new Date(expiry).getTime());
  }
  return 'FFF';
}
function dateToUtcString(dateString) {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = padFront(date.getUTCMonth() + 1);
  const day = padFront(date.getUTCDate());
  const hour = padFront(date.getUTCHours());
  const minute = padFront(date.getUTCMinutes());
  const second = padFront(date.getUTCSeconds());
  return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hour, ":").concat(minute, ":").concat(second, ".0000000Z");
}
function decompressDate(expiry) {
  if (expiry && expiry !== 'FFF') {
    return dateToUtcString(fromCustomBase(expiry));
  }
}
function deflatePause(pause) {
  if (!pause) {
    return 'FFF0FFF0';
  }
  return "".concat(dateToString(pause.expiry)).concat(pause.isExpired ? 1 : 0).concat(dateToString(pause.selfTestExpiry)).concat(pause.type || 0);
}
function deflateGcsData(data) {
  if (!data) {
    return 'FFFFF00';
  }
  return "".concat(data.expiryDate).concat(data.is12hr ? 1 : 0).concat(data.isYoungster ? 1 : 0);
}

const deflate = function () {
  let activeExclusions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return "".concat(activeExclusions.isBlocked ? 1 : 0).concat(activeExclusions.hasTTRestriction ? 1 : 0).concat(activeExclusions.hasGCSRestriction ? 1 : 0).concat(activeExclusions.hasDLRRestriction ? 1 : 0).concat(activeExclusions.hasAMDRestriction ? 1 : 0).concat(activeExclusions.hasTMDTLRestriction ? 1 : 0).concat(deflatePause(activeExclusions.alla)).concat(deflatePause(activeExclusions.bingo)).concat(deflatePause(activeExclusions.casino)).concat(deflatePause(activeExclusions.poker)).concat(deflatePause(activeExclusions.sport)).concat(deflatePause(activeExclusions.tipsspel)).concat(deflatePause(activeExclusions.oddset)).concat(deflateGcsData(activeExclusions.gcsData));
};
function inflatePause(pauseString, vertical) {
  const expiry = decompressDate(pauseString.substr(0, 3));
  const isExpired = Boolean(parseInt(pauseString.substr(3, 1), 10));
  const selfTestExpiry = decompressDate(pauseString.substr(4, 3));
  const type = expiry && parseInt(pauseString.substr(7, 1), 10);
  const needSelfTest = Boolean(selfTestExpiry);
  if (expiry || selfTestExpiry) {
    return new Exclusion({
      expiry,
      isExpired,
      needSelfTest,
      selfTestExpiry,
      type,
      vertical
    });
  }
}
function inflateGcsData(gcsString) {
  const expiryDate = gcsString.substr(0, 5);
  const is12hr = Boolean(parseInt(gcsString.substr(5, 1), 10));
  const isYoungster = Boolean(parseInt(gcsString.substr(6, 1), 10));
  if (expiryDate && expiryDate !== 'FFFFF') {
    return {
      expiryDate,
      is12hr,
      isYoungster
    };
  }
}

const inflate = deflated => {
  if (deflated.length !== 69) {
    return;
  }
  const pausesStartAt = 6;
  const isBlocked = deflated.substr(0, 1) === '1';
  const hasTTRestriction = deflated.substr(1, 1) === '1';
  const hasGCSRestriction = deflated.substr(2, 1) === '1';
  const hasDLRRestriction = deflated.substr(3, 1) === '1';
  const hasAMDRestriction = deflated.substr(4, 1) === '1';
  const hasTMDTLRestriction = deflated.substr(5, 1) === '1';
  const alla = inflatePause(deflated.substr(pausesStartAt, pauseLength), verticals.ALLA);
  const bingo = inflatePause(deflated.substr(pauseLength + pausesStartAt, pauseLength), verticals.BINGO);
  const casino = inflatePause(deflated.substr(pauseLength * 2 + pausesStartAt, pauseLength), verticals.CASINO);
  const poker = inflatePause(deflated.substr(pauseLength * 3 + pausesStartAt, pauseLength), verticals.POKER);
  const sport = inflatePause(deflated.substr(pauseLength * 4 + pausesStartAt, pauseLength), verticals.SPORT);
  const tipsspel = inflatePause(deflated.substr(pauseLength * 5 + pausesStartAt, pauseLength), verticals.TIPSSPEL);
  const oddset = inflatePause(deflated.substr(pauseLength * 6 + pausesStartAt, pauseLength), verticals.ODDSET);
  const gcsData = inflateGcsData(deflated.substr(pauseLength * 7 + pausesStartAt, 7), verticals.SPORT);
  const exclusions = {
    hasPause: false,
    isBlocked,
    hasTTRestriction,
    hasGCSRestriction,
    hasDLRRestriction,
    hasAMDRestriction,
    hasTMDTLRestriction
  };
  if (alla) {
    exclusions.alla = alla;
  }
  if (bingo || casino || poker || sport || tipsspel || oddset) {
    exclusions.hasVerticalPause = true;
  }
  exclusions.hasPause = Boolean(exclusions.hasVerticalPause || exclusions.alla);
  if (exclusions.hasPause) {
    exclusions.hasTotalPause = Boolean(exclusions.alla);
    exclusions.hasVerticalPause = Boolean(exclusions.hasVerticalPause);
  }
  if (bingo) {
    exclusions.bingo = bingo;
  }
  if (casino) {
    exclusions.casino = casino;
  }
  if (poker) {
    exclusions.poker = poker;
  }
  if (sport) {
    exclusions.sport = sport;
  }
  if (tipsspel) {
    exclusions.tipsspel = tipsspel;
  }
  if (oddset) {
    exclusions.oddset = oddset;
  }
  if (gcsData) {
    exclusions.gcsData = gcsData;
  }
  return exclusions;
};
const api = {
  deflate,
  inflate,
  dateToUtcString
};
if (typeof exports === 'object') {
  module.exports = api;
} else {
  svs.accountservices.player_exclusions.compressor = api;
}

 })(window);