(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/views/nar-pause.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/views/nar-pause.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.player_exclusions=_svs.accountservices.player_exclusions||{};
_svs.accountservices.player_exclusions.templates=_svs.accountservices.player_exclusions.templates||{};
svs.accountservices.player_exclusions.templates.nar_pause = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "<p class=\"f-bold\">Du får inte spela!</p>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"noTitle") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":70}}})) != null ? stack1 : "")
    + "\n<p>Detta i och med att du har valt att ta en spelpaus och därmed är avstängd för spel hos samtliga spelbolag som är registrerade hos Spelinspektionen.\nSe <a class=\"alert-text-link\" href=\"http://spelpaus.se\">www.spelpaus.se</a>.</p>Vid frågor, <a class=\"alert-text-link\" href=\"/kundservice/kontakt\">kontakta</a> Svenska Spels Kundservice.\n";
},"useData":true});
Handlebars.partials['components-accountservices-player_exclusions-nar_pause'] = svs.accountservices.player_exclusions.templates.nar_pause;
})(svs, Handlebars);


 })(window);