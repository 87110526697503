(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/views/active-pause.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/views/active-pause.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.player_exclusions=_svs.accountservices.player_exclusions||{};
_svs.accountservices.player_exclusions.templates=_svs.accountservices.player_exclusions.templates||{};
svs.accountservices.player_exclusions.templates.active_pause = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p class=\"f-bold\">"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isExpired") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":119}}})) != null ? stack1 : "")
    + "</p>";
},"2":function(container,depth0,helpers,partials,data) {
    return "Spelpaus fortfarande aktiverad!";
},"4":function(container,depth0,helpers,partials,data) {
    return "Spelpaus Aktiverad!";
},"6":function(container,depth0,helpers,partials,data) {
    return "upphörde";
},"8":function(container,depth0,helpers,partials,data) {
    return "upphör";
},"10":function(container,depth0,helpers,partials,data) {
    return "  <p>\n    Din spelpaus har upphört men du behöver kontakta vår kundservice för att låsa upp ditt spelkonto. Ring <a\n      href=\"tel:0770-11-11-11\"><span class=\"alert-text-link\">0770 11 11 11</span></a>\n    </p>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "  <p>\n    Du kan stänga av dig för spel hos samtliga licensierade spelbolag i Sverige hos Spelinspektionen. Se <a\n      href=\"https://www.spelpaus.se\"><span class=\"alert-text-link\">www.spelpaus.se</span></a>.\n    </p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"noTitle") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":134}}})) != null ? stack1 : "")
    + "\n<p>\n  Du är pausad från:<br>\n  <span class=\"f-capitalize\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"vertical") || (depth0 != null ? lookupProperty(depth0,"vertical") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"vertical","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":41}}}) : helper)))
    + "</span> ("
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isExpired") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":4,"column":50},"end":{"line":4,"column":96}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"expiry") || (depth0 != null ? lookupProperty(depth0,"expiry") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"expiry","hash":{},"data":data,"loc":{"start":{"line":4,"column":97},"end":{"line":4,"column":107}}}) : helper)))
    + ").\n</p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isExpired") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":16,"column":7}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['components-accountservices-player_exclusions-active_pause'] = svs.accountservices.player_exclusions.templates.active_pause;
})(svs, Handlebars);


 })(window);