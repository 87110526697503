(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/react/services/data-service.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/react/services/data-service.js');
'use strict';

class DataService {
  constructor() {
    this.hasValidBankAccount = false;
    this.hasBankAccount = false;
    this.hasError = false;
    this.periodAsText = '';
  }
  setSelfExclusion(subSystem, period) {
    let setActiveExclusions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : () => {};
    let setNaExcluded = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => {};
    this.setActiveExclusions = setActiveExclusions;
    this.setNaExcluded = setNaExcluded;
    this.periodAsText = period.text;
    if (subSystem === 'oddset') {
      subSystem = 'sportsbook';
    }
    if (subSystem === 'tipsspel') {
      subSystem = 'pools';
    }
    if (subSystem === 'Alla spel') {
      subSystem = 'all';
    } else {
      subSystem = subSystem.toLowerCase();
    }
    if (subSystem && period) {
      svs.components.analytics.trackEvent({
        action: 'initiate',
        category: 'spelpaus',
        opt_label: "".concat(subSystem, ":").concat(period)
      });
      if (svs.core.userSession.getLoginType() === 'BANKID') {
        svs.app.bankid.verifySuspensionRequest(undefined, resp => this.onSetSuspensionSuccess(resp, subSystem, period.value), xhr => this.onSetSuspensionFailed(xhr, subSystem, period.value), this);
      } else if (svs.core.userSession.getLoginType() === 'FREJA_EID') {
        svs.app.frejaeid.verifySuspensionRequest(undefined, resp => this.onSetSuspensionSuccess(resp, subSystem, period.value), xhr => this.onSetSuspensionFailed(xhr, subSystem, period.value), this);
      }
      if (subSystem === 'all' && period.value > 11) {
        svs.core.jupiter.call({
          method: 'GET',
          path: '/player/1/bankaccounts'
        }, data => {
          this.hasValidBankAccount = data && data.bankAccounts && data.bankAccounts[0] && data.bankAccounts[0].isValidated;
          this.hasBankAccount = Boolean(data && data.bankAccounts && data.bankAccounts[0] && typeof data.bankAccounts[0].accountNumber === 'string' && data.bankAccounts[0].accountNumber.length > 0);
        }, err => {
          this.hasValidBankAccount = true;
          svs.core.log.getLogger('self-exclusion: ').error('Error getting players bankaccount', err);
        });
      }
    }
  }
  onError(error) {
    svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
      actions: [{
        title: 'Okej'
      }, {
        callback() {
          svs.utils.url.navigate(svs.core.urlMapping.get('customerServiceContact'));
        },
        title: 'Till kundservice'
      }],
      area: svs.ui.dialog.area.POPUP,
      branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
      icon: 'exclamation-sign',
      message: [{
        text: "F\xF6rs\xF6k igen eller kontakta v\xE5r kundservice."
      }],
      title: 'Vi kunde inte aktivera din Spelpaus.'
    }));
    svs.core.log.getLogger('self-exclusion: ').error('Error setting Spelpaus:', error);
  }
  onSetSuspensionFailed(xhr, subSystem, period) {
    const evt = {
      category: 'spelpaus',
      action: 'Cancel',
      opt_label: "".concat(subSystem, ":").concat(period)
    };
    svs.components.analytics.trackEvent(evt);
    if (svs.core.userSession.getLoginType() === 'FREJA_EID') {
      svs.app.frejaeid.suspensionDefaultErrorHandler(xhr);
    } else {
      svs.app.bankid.suspensionDefaultErrorHandler(xhr);
    }
  }
  onSetSuspensionSuccess(resp, excludedSystem, exclusionInMonths) {
    const url = excludedSystem === 'all' ? '/player/1/gametotal/exclusion' : "/player/2/gamevertical/exclusion";
    const options = {
      method: 'PUT',
      path: url,
      data: {
        postToken: resp.postToken,
        excludedSubsystem: excludedSystem,
        exclusionInMonths
      }
    };
    svs.core.jupiter.call(options, data => {
      svs.accountservices.player_exclusions.getActiveExlusions(currentExclusions => {
        const activeExclusions = svs.accountservices.player_exclusions.utils.processAPIData(data, currentExclusions);
        svs.accountservices.player_exclusions.saveExclusions(activeExclusions, () => {
          this.setActiveExclusions(svs.accountservices.player_exclusions.utils.asArray(activeExclusions));
          svs.components.account_balance.get(false, balance => this.showSuccessMessage(balance, excludedSystem, exclusionInMonths), this);
        });
      });
    }, err => {
      this.onError(err);
    });
  }
  showSuccessMessage(accountBalance, product, period) {
    const evt = {
      category: 'spelpaus',
      action: 'Activate',
      opt_label: "".concat(product, ":").concat(period)
    };
    const balance = parseInt(accountBalance, 10);
    if (product === 'all') {
      product = 'alla spel';
    }
    if (product === 'sportsbook') {
      product = 'oddset';
    }
    if (product === 'pools') {
      product = 'tipsspel';
    }
    svs.components.analytics.trackEvent(evt);
    svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
      actions: [{
        title: 'Okej'
      }],
      area: svs.ui.dialog.area.POPUP,
      branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
      icon: 'checkmark',
      message: [{
        text: "Spelpausen g\xE4ller ".concat(this.capitalizeFirstChar(product), " i ").concat(this.periodAsText, ".")
      }],
      title: 'Din spelpaus är aktiverad!'
    }));
    if (product === 'alla spel' && balance > 0 && period > 11) {
      if (this.hasValidBankAccount) {
        svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
          actions: [{
            title: 'Behåll'
          }, {
            callback: () => {
              svs.utils.url.navigate(svs.core.urlMapping.get('transactionsWithdrawal'));
            },
            title: 'Ta ut'
          }],
          area: svs.ui.dialog.area.POPUP,
          branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
          icon: 'withdrawal',
          message: [{
            align: svs.ui.dialog.message.align.CENTER,
            text: "Du har ".concat(svs.utils.format.Currency(accountBalance), " kr p\xE5 ditt spelkonto som kan f\xF6ras \xF6ver till din bank."),
            type: svs.ui.dialog.message.TEXT
          }],
          title: 'Vill du ta ut dina pengar?'
        }));
      } else if (this.hasBankAccount) {
        svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
          actions: [{
            title: 'Okej'
          }],
          area: svs.ui.dialog.area.POPUP,
          branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
          icon: 'withdrawal',
          message: [{
            align: svs.ui.dialog.message.align.CENTER,
            text: "Ditt saldo \xE4r ".concat(svs.utils.format.Currency(accountBalance), " kr."),
            type: svs.ui.dialog.message.TEXT
          }, {
            align: svs.ui.dialog.message.align.CENTER,
            text: 'Vi håller på att verifiera ditt bankkonto så att du kan föra över pengarna till banken.',
            type: svs.ui.dialog.message.TEXT
          }],
          title: 'Du kan ta ut dina pengar inom några dagar'
        }));
      } else {
        svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
          actions: [{
            title: 'Inte nu'
          }, {
            callback: () => {
              svs.utils.url.navigate(svs.core.urlMapping.get('transactionsRegisterBankAccount'));
            },
            title: 'Registrera'
          }],
          area: svs.ui.dialog.area.POPUP,
          branding: svs.ui.dialog.branding[svs.components.lbUtils.branding],
          icon: 'balance',
          message: [{
            align: svs.ui.dialog.message.align.CENTER,
            text: "Ditt saldo \xE4r ".concat(svs.utils.format.Currency(accountBalance), " kr."),
            type: svs.ui.dialog.message.TEXT
          }, {
            align: svs.ui.dialog.message.align.CENTER,
            text: 'Efter registreringen tar det någon dag innan du kan föra över pengarna till din bank.',
            type: svs.ui.dialog.message.TEXT
          }],
          title: 'Vill du registrera ett bankkonto för uttag?'
        }));
      }
    }
  }
  capitalizeFirstChar(text) {
    const textArr = text.split('');
    textArr[0] = textArr[0].toUpperCase();
    return textArr.join('');
  }
}
svs.accountservices.player_exclusions.DataService = new DataService();

 })(window);