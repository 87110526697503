(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/template-literals/gcs-restriction.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/template-literals/gcs-restriction.js');
'use strict';

svs.accountservices.player_exclusions.template_literals.gcs_restriction = data => {
  let content = "Visste du att du de senaste 12 timmarna har f\xF6rlorat mer \xE4n ".concat(data !== null && data !== void 0 && data.isYoungster ? '5 000' : '10 000', " kr?");
  if (data !== null && data !== void 0 && data.is12hr) {
    content = "De senaste timmarna har du fortsatt att f\xF6rlora pengar.";
  }
  return "\n    <p>\n      ".concat(content, "\n      Av omtanke om dig kan du d\xE4rf\xF6r inte s\xE4tta in pengar eller spela just nu.\n    </p>\n    <p>\n      Du kan s\xE4tta in pengar och spela igen klockan ").concat(data === null || data === void 0 ? void 0 : data.expiryDate, ".\n    </p>\n    <p>\n      Vill du veta mer? Kontakta oss p\xE5 <a href=\"tel:+0770111111\">0770-11 11 11</a>.\n    </p>\n  ");
};

 })(window);