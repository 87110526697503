(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/views/tt-restriction.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/views/tt-restriction.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.player_exclusions=_svs.accountservices.player_exclusions||{};
_svs.accountservices.player_exclusions.templates=_svs.accountservices.player_exclusions.templates||{};
svs.accountservices.player_exclusions.templates.tt_restriction = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "<p class=\"margin-top-1\">\n  Anledningen till att du just nu inte kan spela eller sätta in pengar är att du den senaste tiden spelat mer riskfyllt.\n</p>\n<p>\n  Innan du kan spela vidare behöver du kontakta oss för att prata om dina spelvanor. Du kan välja mellan att chatta eller prata med någon av våra omsorgskommunikatörer.\n</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "<p class=\"margin-top-1\">\n  Vi har försökt kontakta dig för att hitta tid för ett samtal om hur du känner kring dina spelvanor. Tills vi får tag på dig kan du inte spela eller sätta in pengar.\n</p>\n<p>\n  Kontakta oss på ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":18},"end":{"line":13,"column":72}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "0498-20 02 91";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isMobile") || (depth0 != null ? lookupProperty(depth0,"isMobile") : depth0)) != null ? helper : alias2),(options={"name":"isMobile","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":85},"end":{"line":13,"column":115}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isMobile")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " för att hitta tid för ett samtal.\n</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "<a href=\"tel:0498-20 02 91\">";
},"6":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"feature")||(depth0 && lookupProperty(depth0,"feature"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ft-call-of-care-update",{"name":"feature","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":15,"column":12}}})) != null ? stack1 : "")
    + "\n\n\n";
},"useData":true});
Handlebars.partials['components-accountservices-player_exclusions-tt_restriction'] = svs.accountservices.player_exclusions.templates.tt_restriction;
})(svs, Handlebars);


 })(window);