(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/template-literals/tt-restriction.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/template-literals/tt-restriction.js');
'use strict';

const isMobile = svs.core.detect.formfactor.mobile();
svs.accountservices.player_exclusions.template_literals.tt_restriction = () => {
  if (svs.core.detect.feature('ft-call-of-care-update')) {
    return "\n  <p class=\"margin-top-1\">\n  Anledningen till att du just nu inte kan spela eller s\xE4tta in pengar \xE4r att du den senaste tiden spelat mer riskfyllt.\n  </p>\n  <p>\n  Innan du kan spela vidare beh\xF6ver du kontakta oss f\xF6r att prata om dina spelvanor. Du kan v\xE4lja mellan att chatta eller prata med n\xE5gon av v\xE5ra omsorgskommunikat\xF6rer.\n  </p>\n";
  }
  return "\n  <p class=\"margin-top-1\">\n    Vi har f\xF6rs\xF6kt kontakta dig f\xF6r att hitta tid f\xF6r ett samtal om hur du k\xE4nner kring dina spelvanor. Tills vi f\xE5r tag p\xE5 dig kan du inte spela eller s\xE4tta in pengar.\n  </p>\n  <p>\n    Kontakta oss p\xE5 ".concat(isMobile ? '<a href="tel:0498-20 02 91">' : '', "0498-20 02 91").concat(isMobile ? '</a>' : '', " f\xF6r att hitta tid f\xF6r ett samtal.\n  </p>\n");
};

 })(window);