(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/views/gcs-restriction.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/views/gcs-restriction.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.accountservices=_svs.accountservices||{};
_svs.accountservices.player_exclusions=_svs.accountservices.player_exclusions||{};
_svs.accountservices.player_exclusions.templates=_svs.accountservices.player_exclusions.templates||{};
svs.accountservices.player_exclusions.templates.gcs_restriction = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "    De senaste timmarna har du fortsatt att förlora pengar. \n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    Visste du att du de senaste 12 timmarna har förlorat mer än "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isYoungster") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":109}}})) != null ? stack1 : "")
    + " kr? \n";
},"4":function(container,depth0,helpers,partials,data) {
    return "5 000";
},"6":function(container,depth0,helpers,partials,data) {
    return "10 000";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is12hr") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":6,"column":9}}})) != null ? stack1 : "")
    + "  Av omtanke om dig kan du därför inte sätta in pengar eller spela just nu. \n</p>\n<p>\n  Du kan sätta in pengar och spela igen klockan "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"expiryDate") || (depth0 != null ? lookupProperty(depth0,"expiryDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"expiryDate","hash":{},"data":data,"loc":{"start":{"line":10,"column":48},"end":{"line":10,"column":62}}}) : helper)))
    + ".\n</p>\n<p>\n   Vill du veta mer? Kontakta oss på <a href=\"tel:+0770111111\">0770-11 11 11</a>.\n</p>\n";
},"useData":true});
Handlebars.partials['components-accountservices-player_exclusions-gcs_restriction'] = svs.accountservices.player_exclusions.templates.gcs_restriction;
})(svs, Handlebars);


 })(window);